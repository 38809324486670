import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux'; 
import Header from 'components/Headers/Header';
// import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import {Container, Row, Col, Button, Spinner, Alert, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import { useTranslation } from 'react-i18next';

function Satellite() {

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const [farmeroptions, setFarmeroptions] = useState([]);
    const [farmerselected, setfarmerselected] = useState('');

    const [htmlContent, setHtmlContent] = useState('');
    const [htmlContentPlot, sethtmlContentPlot] = useState('');
    const [vegetationindex, setVegetationIndex] = useState('ndvi')
  
    const [loading, setLoading] = useState(true);

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");  
    
    // const [fieldData, setFieldData] = useState({
    //     Field: '',
    //     Centroid: '',
    //     Crop: '',
    //     CropRotation: '',
    //     OrganicVsConventional: ''
    // });
    
    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    useEffect(() => {
        // Funzione per effettuare la richiesta GET e aggiornare lo stato delle opzioni
        const fetchData = async () => {
            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable`, config);
                
                setFarmeroptions(response.data);

            } catch (error) {
                setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
                setError(true)
                setTimeout(function() {
                setError(false)
                }, 5000);
                return;
            }
        };

        fetchData(); 
    }, [token]);

    const handleSelectChange1 = async (e) => {
        
        setfarmerselected(e.target.value)
        
        const farmersele = e.target.value

        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farmersele}`, config);
                        
            setOptions(response.data);

        } catch (error) {
            setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
            setError(false)
            }, 5000);
            return;
        }
    };

    const handleSelectChange = async () => {

        setLoading(false);

        console.log(options)

        try {
            
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }

            if (selectedOption==='') {

                console.log(options[0])
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/satellite?field=${options[0]}&vegetationindex=${vegetationindex}`, config);
                setHtmlContent(response.data);
                
                const responsenew = await axios.get(`${process.env.REACT_APP_BACKEND_API}/satellite/timeseries?field=${options[0]}&vegetationindex=${vegetationindex}`, config);
                sethtmlContentPlot(responsenew.data);
    
                // const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${options[0]}`, config);

                // console.log(responsefield)

                // setFieldData({
                //     Field: responsefield.data,
                //     Centroid: responsefield.data.properties.centroid,
                //     Crop: responsefield.data.properties.ActualCrop,
                //     CropRotation: responsefield.data.properties.CropRotation,
                //     OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
                // });

            } else {

                console.log(selectedOption)

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/satellite?field=${selectedOption}&vegetationindex=${vegetationindex}`, config);
                setHtmlContent(response.data);
                
                const responsenew = await axios.get(`${process.env.REACT_APP_BACKEND_API}/satellite/timeseries?field=${selectedOption}&vegetationindex=${vegetationindex}`, config);
                sethtmlContentPlot(responsenew.data);
    
                // const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${selectedOption}`, config);

                // console.log(responsefield)
                
                // setFieldData({
                //     Field: responsefield.data,
                //     Centroid: responsefield.data.properties.centroid,
                //     Crop: responsefield.data.properties.ActualCrop,
                //     CropRotation: responsefield.data.properties.CropRotation,
                //     OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
                // });
            }
          
        } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
            setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            return;
        }

        setLoading(true);
    };

    return (
        <>
            <Header/>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                        <Card className="shadow">
                            <CardHeader>
                                <h3>{t('Satellite Image analysis')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>{t('Select the Farm')}</Form.Label>
                                        <Form.Control 
                                            as="select" 
                                            onChange={handleSelectChange1} 
                                            value={farmerselected}>
                                                <option key={""} value={""}>
                                                    
                                                </option>
                                                {farmeroptions.map((farmer, index) => (
                                                    <option key={index} value={farmer.NameFarmer}>
                                                        {farmer.NameFarmer}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                        <br></br>

                                        <Form.Label>{t('Select the field to analyze')}</Form.Label>
                                        <Form.Control 
                                            as="select" 
                                            // onChange={handleSelectChange} 
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                            value={selectedOption}>
                                                <option key={""} value={""}>
                                                    
                                                </option>
                                            {options.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <br></br>
                                        <Form.Label>{t('Select the Vegetation Index')}</Form.Label>
                                        <Form.Control 
                                            as="select" 
                                            onChange={(e) => setVegetationIndex(e.target.value)}>
                                            <option value="ndvi">
                                                NDVI
                                            </option>
                                            <option value="msavi2">
                                                MSAVI2
                                            </option>
                                            <option value="evi2">
                                                EVI2
                                            </option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                                <br></br>
                                <br></br>
                                <CardFooter>
                                    {loading ? (
                                        <>
                                            <Button color="success" onClick={handleSelectChange}> {t('View Satellite Image')}</Button>
                                        </>
                                    ):(
                                        <>
                                            <Button color="success" disabled>
                                                <Spinner size="sm">
                                                    Loading...
                                                </Spinner>
                                                <span>
                                                {' '}Loading
                                                </span>
                                            </Button>
                                        </>
                                    )}
                                    {error && <Alert color="danger" fade={false}>{message}</Alert>}
                                    <br></br>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    </Col>                    
                    <br></br>
                    {htmlContent && (
                        <>
                            <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                                <Card className="shadow">
                                    <CardHeader>
                                        <h3>{t('Satellite Image')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <iframe id="iframe1" width="100%" height="400" srcDoc={htmlContent}></iframe>
                                    </CardBody>
                                </Card>
                            </Col>
                            <br></br>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card className="shadow">
                                    <CardHeader>
                                        <h3>{t('Time series plot')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <iframe id="iframe2" width="100%" height="400" srcDoc={htmlContentPlot}></iframe>
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
        </>
    )
}

export default Satellite
