import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Container, Row, Col, Card, CardHeader, Spinner, CardBody, Table, CardFooter, Button } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import * as XLSX from 'xlsx';  // Import XLSX for Excel export
import Header from 'components/Headers/Header';

function SoilData() {

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const [loading, setloading] = useState(true);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const [loadingsubmit, setloadingsubmit] = useState(true);

    const [FieldData, setFieldData] = useState({
        Field: '',
        FieldName: '',
        Centroid: '',
        Crop: '',
        CropRotation: '',
        OrganicVsConventional: '',
        Id:''
    });
    
    const token = useSelector((state) => state.userLogin.userInfo.token);

    const [downalodexceldata, setDownloadexceldata] = useState(true)

    useEffect(() => {

        const fetchData = async () => {

            try {

                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldname/`, config);
                
                setOptions(response.data);

            } catch (error) {
                console.log(error)
            } finally {
                setloading(false)
            }
        };

        fetchData(); 
    }, [token]);



    const [soildata, setsoildata] = useState([]);

    const handleSelectChange = async (e) => {

        setloadingsubmit(false)

        const selectedfield = e.target.value
        setSelectedOption(e.target.value)

        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
              }
          
              const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${selectedfield}`, config);

              setFieldData({
                Field: responsefield.data,
                FieldName: responsefield.data.properties.FieldName,
                Centroid: responsefield.data.properties.centroid,
                Crop: responsefield.data.properties.ActualCrop,
                CropRotation: responsefield.data.properties.CropRotation,
                OrganicVSConventional: responsefield.data.properties.OrganicVSConventional,
                Id: responsefield.data.id
              });

              const responsesoildata = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-data/soil-data/${selectedfield}`, config);

            //   const responseData = JSON.parse(responsesoildata.data);

              setsoildata(responsesoildata.data);               

        } catch(error) {
            console.log(error)
        } finally {
            setloadingsubmit(true)
        }

    };

    const submitdownload = async () => {
        setDownloadexceldata(false);

        // Convert soildata to Excel file
        const worksheet = XLSX.utils.json_to_sheet(soildata);  // Convert soil data to worksheet format
        const workbook = XLSX.utils.book_new();  // Create a new workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SoilData');  // Append the worksheet to the workbook

        // Generate a download for the Excel file
        XLSX.writeFile(workbook, 'SoilData.xlsx');  // Download the Excel file

        setDownloadexceldata(true);
    };


    return (
        <div>
            <Header />
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Set the field to get the soil data')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <Form.Control 
                                                as="select" 
                                                onChange={handleSelectChange}
                                                value={selectedOption}>
                                                    <option value=''>

                                                    </option>
                                                {options.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form>
                                        <br></br>
                                        {loadingsubmit ? (
                                            <>
                                            </>
                                        ):(
                                            <>
                                                <Spinner size="xl">
                                                    {t('Loading...')}
                                                </Spinner>
                                            </>
                                        )}
                                        <br></br>
                                        {FieldData && FieldData.Id !== '' ? (
                                            <>
                                                <MapContainer center={[FieldData.Centroid[1], FieldData.Centroid[0]]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(FieldData.Field)}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    <FeatureGroup>
                                                    <GeoJSON      
                                                        data={{
                                                            type: 'Feature',
                                                            geometry: {
                                                            type: 'Polygon',
                                                            coordinates: FieldData.Field.geometry.coordinates,
                                                            },
                                                            properties: FieldData.Field.properties,
                                                        }}
                                                        style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                                                    />
                                                    </FeatureGroup>
                                                </MapContainer>                                                
                                            </>
                                        ) : (
                                            <>

                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            {soildata && soildata.length !== 0 ? (
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Card>
                                            <CardHeader>
                                                <h3>{t('Table of the Soil Data')}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">{t('Depth start (cm)')}</th>
                                                            <th scope="col">{t('Depth end (cm)')}</th>
                                                            <th scope="col">{t('Mean Bulk density (cg/cm3)')}</th>
                                                            <th scope="col">{t('Uncertainty Bulk density (cg/cm3)')}</th>
                                                            <th scope="col">{t('Mean Cation Exchange Capacity (mmol/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty Cation Exchange Capacity (mmol/kg)')}</th>
                                                            <th scope="col">{t('Mean Clay (g/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty Clay (g/kg)')}</th>
                                                            <th scope="col">{t('Mean Nitrogen (cg/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty Nitrogen (cg/kg)')}</th>
                                                            <th scope="col">{t('Mean pH')}</th>
                                                            <th scope="col">{t('Uncertainty pH')}</th>
                                                            <th scope="col">{t('Mean Sand (g/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty Sand (g/kg)')}</th>
                                                            <th scope="col">{t('Mean Silt (g/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty Silt (g/kg)')}</th>
                                                            <th scope="col">{t('Mean SOC (g/kg)')}</th>
                                                            <th scope="col">{t('Uncertainty SOC (g/kg)')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {soildata.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{data.Depth_start_cm}</td>
                                                                <td>{data.Depth_end_cm}</td>
                                                                <td>{data.Bulk_Density_cg_cm3_mean}</td>
                                                                <td>{data.Bulk_Density_cg_cm3_uncertainty}</td>
                                                                <td>{data.Cation_Exchange_Capacity_mmol_kg_mean}</td>
                                                                <td>{data.Cation_Exchange_Capacity_mmol_kg_uncertainty}</td>
                                                                <td>{data.clay_g_kg_mean}</td>
                                                                <td>{data.clay_g_kg_uncertainty}</td>
                                                                <td>{data.nitrogen_cg_kg_mean}</td>
                                                                <td>{data.nitrogen_cg_kg_uncertainty}</td>
                                                                <td>{data.ph_mean}</td>
                                                                <td>{data.ph_uncertainty}</td>
                                                                <td>{data.sand_g_kg_mean}</td>
                                                                <td>{data.sand_g_kg_uncertainty}</td>
                                                                <td>{data.silt_g_kg_mean}</td>
                                                                <td>{data.silt_g_kg_uncertainty}</td>
                                                                <td>{data.soc_g_kg_mean}</td>
                                                                <td>{data.soc_g_kg_uncertainty}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                            <CardFooter>
                                                {downalodexceldata ? (
                                                    <>
                                                        <Button color="success" onClick={submitdownload}> {t('Download')}</Button>
                                                    </>
                                                ):(
                                                    <>
                                                        <Button color="success" disabled>
                                                            <Spinner size="sm">
                                                                Loading...
                                                            </Spinner>
                                                            <span>
                                                            {' '}Loading
                                                            </span>
                                                        </Button>
                                                    </>
                                                )}
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </>
                            ):null}                            
                        </Row>
                    </Container>
                </>
            )}
        </div>
    );
}

export default SoilData;
